:root {
  --primary: #322372;
  --secondary: #7356f1;
  --authBg: #f4faff;
  --white: #ffffff;
  --orangZe: #ff6636;
}

/* ALL SIDEBAR CLASSES TO CHANGE style*/
.sidebar aside.ant-layout-sider.ant-layout-sider-dark {
  background: var(--white) !important;
}
.sidebar .ant-menu {
  background-color: var(--white);
  color: black;
  padding-bottom: 50px;
}
/* .sidebar .ant-layout {
  width: 100% !important;
} */

.ant-menu-item {
  color: #6c757d !important;
  font-weight: 500;
}
.sidebar .ant-menu-submenu-title {
  color: #000 !important;
}

.sidebar .ant-menu .ant-menu-item-selected {
  background: linear-gradient(
    90deg,
    rgba(115, 86, 241, 0) 0%,
    rgba(115, 86, 241, 0.4) 100%
  );
  border-radius: 0px !important;
  color: var(--secondary) !important;
  border-right: 5px solid var(--secondary);
  /* border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important; */
}
.sidebar .ant-layout-header {
  padding-inline: 0;
}
.sidebar .ant-menu-item:hover {
  background-color: var(--white) !important; /* when hovering over on an item */
}
.sidebar .ant-menu-item-selected:hover {
  background-color: var(--white) !important; /* when hovering over on an item */
}
.sidebar li.ant-menu-item:active {
  background-color: var(--bs-white) !important;
}
.sidebar .ant-menu .ant-menu-item-selected:active {
  background-color: var(--white) !important;
}
.sidebar .ant-menu .ant-menu-submenu-selected .ant-menu-title-content {
  color: var(--secondary) !important;
}
.sidebar .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: black !important;
}
.sidebar .ant-menu-submenu .ant-menu-inline {
  background: var(--bs-white) !important;
  border-radius: 10px !important;
  /* margin:0px 15px 0px 15px !important; */
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--bs-white);
  color: #000;
}
.ant-menu-submenu-popup
  .ant-menu
  .ant-menu-submenu
  .ant-menu-submenu-title
  .ant-menu-title-content
  p,
.ant-menu-submenu-arrow {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--bs-white);
  color: #000 !important;
}
.ant-layout-sider-collapsed {
  width: 50px !important;
  min-width: 50px !important;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item-selected {
  background-color: var(--white);
}
.ant-menu .ant-menu-sub {
  background-color: var(--bs-white) !important;
}
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c8ced4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--white);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(
    --orange
  ) !important; /* Change the background color to green when checked */
  border: none !important;
  border-radius: 0px;
}
.ant-select-selector {
  border-radius: 0px !important;
  /* border: none !important; */
}
.searchbar .ant-select-selector{
  border-radius: 8px !important;
}
.ant-checkbox-inner::after {
  border-color: white; /* Change the tick mark color to white */
}

.slick-arrow {
  display: none !important;
}
.ant-layout-header {
  background-color: var(--primary) !important;
}
/* .filterdropdown:hover .ant-select-selector {
  border: 1px solid lightgray !important;
} */
.filterdropdown .ant-select-selector {
  background: #f7f8fa !important;
  border-radius: 3px !important;
}

/* table css */

.ticketList .ant-table-thead > tr > th {
  background: white !important;
  color: #b7b9bb !important;
  border: none !important;
  @apply font-poppins;
}
.ticketList .ant-table-tbody {
  font-weight: 600;
  @apply font-poppins;
}
.ContactList .ant-table-thead > tr > th {
  background: var(--secondary) !important;
  color: white !important;
  border: none !important;
  @apply font-poppins;
}
.ContactList .ant-table-tbody {
  font-weight: 600;
  @apply font-poppins;
}
.ant-table-content {
  max-width: 100%;
  overflow-x: scroll;
}
.ant-table-content::-webkit-scrollbar {
  display: none;
}
.ant-table-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* PAGINATION CSS */
.ant-pagination-item {
  background: none !important;
  @apply font-bold text-[#6C757D] font-inter;
}
.ant-pagination-item-active {
  border: none !important;
  background-color: var(--secondary) !important;
  color: white !important;
  border-radius: 50px !important;
  @apply bg-secondary text-white;
}
.ant-pagination-item-active a {
  color: white !important;
}

.ant-switch-checked {
  background-color: var(--secondary) !important;
}

.recepientarea .ant-select-selector {
  padding: 10px !important;
  border-radius: 5px !important;
  background-color: rgb(252, 252, 252) !important;
}
.recepientarea
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item {
  margin: 2px !important;
  background-color: var(--secondary) !important;
  @apply font-poppins text-white font-semibold text-xs rounded-md;
}
.recepientarea
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item-suffix {
  background-color: transparent !important;
  color: black !important;
}
.recepientarea
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item
  .ant-select-selection-item
  .ant-select-selection-item-remove {
  @apply font-poppins text-white rounded-md font-semibold;
}

/* Calendar container styling */
.calendar-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

/* Calendar header styling */
.ant-picker-calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f2f5;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 10px 10px 0 0;
}

.ant-picker-calendar-header .ant-picker-header-view button {
  color: #1890ff;
}

/* Calendar table styling */
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border-radius: 0 0 10px 10px;
}

.ant-picker-calendar .ant-picker-cell-inner {
  height: 30px;
  line-height: 30px;
}

.ant-picker-calendar .ant-picker-cell {
  border-radius: 5px;
  transition: background-color 0.3s;
  text-align: center !important;
  border: 1px solid gainsboro !important;
}

.ant-picker-calendar .ant-picker-cell:hover {
  background-color: #e6f7ff;
}

.ant-picker-calendar .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-calendar .ant-picker-cell-selected:hover .ant-picker-cell-inner {
  background-color: #1890ff;
  color: #fff;
  border-radius: 5px;
}
.ant-picker-content thead tr th {
  text-align: center !important;
  border: 1px solid gainsboro !important;
  padding: 10px !important;
}

/* ANTD PROFILE IMAGE MODAL */
.image-Modal .ant-modal-content{
  padding:0;
  border-radius: 50%;
}
.image-Modal .ant-modal-content button {
  display: none !important;
}